import React from 'react'
import HeaderPaginas from '../../../../components/headerPaginas'
import Layout from '../../../../components/layout'
import Seo from '../../../../components/SEO/SEO'
import CtaServicios from '../../../../components/ctaServicios'
import { GrWifi, GrGlobe } from "react-icons/gr"
import { RiVirusLine, RiSpyFill } from "react-icons/ri"
import { BiMask } from "react-icons/bi"
import { IconContext } from 'react-icons'

export default function Integracion(props) {
    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Red Team" imagen="/img/cabeceras/red-team-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>La mejor manera de saber cuánto daño puede hacer a tu empresa un ataque, es que nosotros te ataquemos primero. Puede que te parezca una práctica muy radical, pero ayudará a fortalecer tu seguridad.</p>
                </div>
            </div>
            <div className="cuerpo-servicio sin-hueco">
                <section className="limitador bloque-grid">
                    <div className="titulo destacado">
                        <h2>¿Qué es el Red Team?</h2>
                    </div>
                    <div className="texto">
                        <p>Un grupo de personas del equipo Bullhost simularán un ataque dirigido a tu organización en un ejercicio a través del cual comprobarán qué posibilidad existe de tener acceso a los sistemas, cómo pueden comprometerlos y qué tipo de impacto podría tener esto en tu negocio. El Red Team atacará, tu organización deberá demostrar que está blindada para que este equipo se quede tranquilo.</p>
                    </div>
                </section>
                <section className="limitador bloque-grid">
                    <div className="titulo destacado">
                        <h2>¿Dónde se centra el Red Team?</h2>
                    </div>
                    <div className="texto">
                        <p>A través de la auditoría del Red Team tan sólo se tratan de localizar aquellas vulnerabilidades más críticas y que,por tanto, permiten el acceso a un atacante a la información de la organización.
                        Aquellas vulnerabilidades más leves no suponen una prioridad para ellos.</p>
                        <p>Tu equipo de personas NO estarán avisados, el factor sorpresa es importante, ya que lo que busca el Red Team es conocer si tu empresa está preparada o no ante un escenario hostil.</p>
                        <p>Tan sólo las personas directivas o del equipo de sistemas que han solicitado la realización del ataque (White Team) y a los que se les dará los resultados del mismo, tienen constancia de ello.</p>
                    </div>
                </section>
                <div className="limitador">
                    <div className="fila-cards-cpd-propio-caracteristicas">
                        <h2>Metodología Red Team</h2>
                        <p>No inventamos la rueda. Las metodologías son cómunes para casi todos los escenarios y se pueden resumir en las siguientes fases:</p>
                        <div className="card-cpd-propio-caracteristicas">
                            <div className="col-imagen" style={{ backgroundImage: "url(/img/red-team-1.jpg)" }}>
                            </div>
                            <div className="col-texto">
                                <div>
                                    <h3>Definición y planificación.</h3>
                                    <p>Aquí se determinan aquellos elementos o vectores de acceso que serán utilizados y cómo serán atacados.</p>
                                </div>
                                <div>
                                    <h3>Reconocimiento: desde fuera.</h3>
                                    <p>Nuestro equipo Red Team la única info con la que parte es el nombre de tu empresa. En esta fase se desarrollarán todas las acciones viables para identificar aquellos elementos expuestos y que se vayan a chequear, para posteriormente, identificar sus vulnerabilidades y poder realizar la intrusión.</p>
                                </div>
                                <div>
                                    <h3>Vulnerabilidad inicial</h3>
                                    <p>Aquí se identifica una vulnerabilidad crítica que nos permita ejecutar una intrusión, y puede ser desde hacer ataques de fuerza bruta para obtener usuarios, hasta ejecutar una subida de ficheros que permita acceder a tu red interna.</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-cpd-propio-caracteristicas">
                            <div className="col-texto">
                                <div>
                                    <h3>Acceso a la red interna</h3>
                                    <p>Ya tenemos comprometido un primer activo, y en estos momentos el equipo se preparará para encontrar el camino y cómo acceder a la red interna. Esta parte del ataque puede variar en función de la seguridad de tu organización y puede llevar minutos o incluso días.</p>
                                </div>
                                <div>
                                    <h3>Elevación de privilegios</h3>
                                    <p>Aquí el equipo dedicará sus esfuerzos en crear vías de acceso secundarias en caso que la defensa de tu empresa detecte el ataque al vector principal y lo haya detenido. El objetivo es poder continuar con el ejercicio, siempre.</p>
                                </div>
                                <div>
                                    <h3>Reconocimiento interno</h3>
                                    <p>Si el equipo lográ acceder a toda la organización, lo primero que ejecutamos es un reconocimiento interno de todos los activos y de esa forma poder evaluar cuáles podrían ser los ataques más radicales que se pueden recibir.</p>
                                </div>
                            </div>
                            <div className="col-imagen" style={{ backgroundImage: "url(/img/red-team-2.jpg)" }}></div>
                        </div>
                    </div>
                </div>
                <section className="bloque-oscuro">
                    <div id="tips-red-team" className="bloque-tips limitador contenido__servicio bloque-tips__bloque-oscuro">
                    <h2>¿Cuáles son los vectores de ataque más frecuentes o comunes del Red Team?</h2>
                    <IconContext.Provider value={{ fill: "#fff", stroke: "#fff", color: "#fff", size: "40%", className: "serv-icono-svg" }}>
                    <ul>
                        <li>
                            <div className="tip-icono"><GrGlobe /></div>
                            <p>
                            Tus activos en internet
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><GrWifi/></div>
                            <p>
                            Infraestructura wifi
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><RiVirusLine/></div>
                            <p>
                            USB con malware
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><BiMask/></div>
                            <p>
                            Phishing
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono">
                                <RiSpyFill/>
                                {/* <GrRun/> */}
                                </div>
                            <p>
                            Intrusión física
                            </p>
                        </li>
                    </ul>
                    </IconContext.Provider>
                    </div>
                </section>
            </div>
            <CtaServicios/>
        </Layout>
    )
}